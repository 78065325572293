const messages = {
  nav: {
    about: "About",
    cta: "Get a demo",
    home: "Home",
    logout: "Logout",
    search: "Find your community"
  }
};

export default messages;
