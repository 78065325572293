const messages = {
  images: {
    demo: {
      alt: "Couple looking at an iPad",
      src: "https://d13jnmalmsksln.cloudfront.net/marketing-images/www-demo-image-formatted1.png"
    },
    hero: {
      alt: "Smiling woman with phone",
      src: "https://d13jnmalmsksln.cloudfront.net/marketing-images/www-main-image-formatted3.png"
    },
    logos: {
      businessInsider: {
        alt: "Business Insider",
        src: "https://d13jnmalmsksln.cloudfront.net/logos/business_insider-2.png"
      },
      christensenInstitute: {
        alt: "Christensen Institute",
        src: "https://d13jnmalmsksln.cloudfront.net/logos/christensen_institute.png"
      },
      cnbc: {
        alt: "CNBC",
        src: "https://d13jnmalmsksln.cloudfront.net/logos/cnbc.png"
      },
      forbes: {
        alt: "Forbes",
        src: "https://d13jnmalmsksln.cloudfront.net/logos/forbes.png"
      },
      ladders: {
        alt: "Ladders",
        src: "https://d13jnmalmsksln.cloudfront.net/logos/ladders.png"
      },
      universityBusiness: {
        alt: "University Business",
        src: "https://d13jnmalmsksln.cloudfront.net/logos/university_business.png"
      }
    }
  },
  sections: {
    demo: {
      cta: "Get a demo",
      description:
        "Getting started has never been easier. Meet our dedicated partner team and get to know the product with a personalized demo of Alumnifire.",
      heading: "Unlock your alumni network with Alumnifire today"
    },
    features: {
      description:
        "Alumnifire simplifies community engagement because unnecessary features lead to confusion, not engagement.",
      directory: {
        description: "Dynamic member directory with customized profiles, easy search, and suggested connections.",
        heading: "Directory"
      },
      employers: {
        description: "Automatically surface opportunities at organizations where members work.",
        heading: "Employer Network"
      },
      events: {
        description: "Streamlined events calendar with RSVP functionality and optional integrations.",
        heading: "Events"
      },
      forum: {
        description: "Foster discussion and Q&A among your members for greater support.",
        heading: "Forum"
      },
      groups: {
        description: "Networking tools for smaller groups within your larger community.",
        heading: "Affinity Groups"
      },
      heading: "Focused on the alumni engagement features that matter most.",
      jobs: {
        description: "Intuitive job board where members and admins can post jobs in seconds.",
        heading: "Jobs"
      }
    },
    hero: {
      cta: "Let's talk",
      description:
        "An industry-leading alumni solution that's straightforward, effective, and economical. Unlock your alumni community's potential with all the tools you need without the clutter you don't.",
      heading: "A powerful, yet simple alumni management platform."
    },
    trending: {
      heading: "Alumni communities trending now"
    }
  },
  title: "Alumnifire | Home"
};

export default messages;
