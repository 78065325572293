// A generic nested map is a javascript object with infinite depth, where the leaf nodes are strings.
type GenericNestedMap<T> = Record<string, string | T>;
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-empty-object-type
interface NestedMap extends GenericNestedMap<NestedMap> {}

// Recursively convert a nested map to a flat map, with the keys in dot notation.
const flatten = (inputMap: NestedMap, parentKey = ""): Record<string, string> => {
  let result: Record<string, string> = {};

  for (const key in inputMap) {
    const value = inputMap[key];

    const dotNotationKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "string") {
      result[dotNotationKey] = value;
    } else if (typeof value === "object") {
      const nestedResult = flatten(value, dotNotationKey);
      result = { ...result, ...nestedResult };
    }
  }

  return result;
};

export default flatten;
