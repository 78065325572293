const messages = {
  errors: {
    email: {
      format: "Invalid email format",
      required: "Email is required"
    },
    password: {
      required: "Password is required"
    }
  },
  fields: {
    email: {
      label: "Email",
      placeholder: "me@example.com"
    },
    password: {
      label: "Password",
      placeholder: "S3cr3tP@ssw0rd"
    }
  },
  submit: "Login"
};

export default messages;
