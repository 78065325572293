import { ComponentProps } from "react";
import { IntlProvider } from "react-intl";

import AppMessages from "@components/app.messages.en";
import LoginFormMessages from "@components/forms/login-form.messages.en";
import RequestDemoFormMessages from "@components/forms/request-demo-form.messages.en";
import WwwLayoutMessages from "@components/layouts/www-layout.messages.en";
import LogoMessages from "@components/logo.messages.en";
import DemoModalMessages from "@components/overlays/demo-modal.messages.en";
import AboutPageMessages from "@components/pages/about-page.messages.en";
import CommunitySearchPageMessages from "@components/pages/community-search-page.messages.en";
import HomePageMessages from "@components/pages/home-page.messages.en";
import LoginPageMessages from "@components/pages/login-page.messages.en";
import NotFoundPageMessages from "@components/pages/not-found-page.messages.en";
import flatten from "@utils/flatten";

const nestedTranslations = {
  components: {
    AboutPage: AboutPageMessages,
    App: AppMessages,
    CommunitySearchPage: CommunitySearchPageMessages,
    DemoModal: DemoModalMessages,
    HomePage: HomePageMessages,
    LoginForm: LoginFormMessages,
    LoginPage: LoginPageMessages,
    Logo: LogoMessages,
    NotFoundPage: NotFoundPageMessages,
    RequestDemoForm: RequestDemoFormMessages,
    WwwLayout: WwwLayoutMessages
  },
  dictionary: {
    test: "Test"
  }
};

const en: NonNullable<ComponentProps<typeof IntlProvider>["messages"]> = flatten(nestedTranslations);

export default en;
