const messages = {
  errors: {
    community: {
      required: "Community is required"
    },
    email: {
      format: "Invalid email format",
      required: "Email is required"
    },
    name: {
      required: "Name is required"
    }
  },
  fields: {
    fromAddress: {
      label: "Your email",
      placeholder: "me@example.com"
    },
    message: {
      label: "What do you hope Alumnifire can do for you?",
      placeholder: "We are looking to expand our job shadowing program."
    },
    organizationName: {
      label: "Where you work, or the community you manage",
      placeholder: "Hogwarts School of Witchcraft and Wizardry"
    },
    referralSource: {
      label: "How did you hear about us?",
      placeholder: "One of your clients told us about you."
    },
    senderName: {
      label: "Your name",
      placeholder: "Jane Smith"
    }
  },
  submit: "Send request",
  success: "Your demo request has been sent to us. We'll be in touch soon."
};

export default messages;
